import React from 'react'
import { createRoot } from 'react-dom'

import loadable from './utils/functions/loadable'

console.log('debug', process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const App = loadable(() => import('./App'))

createRoot(document.getElementById('root')).render(<App />)
